/*
 * Counter number
 */
$('.number').counterUp({
  delay: 10,
  time: 2000
});

$(".page-template-template-demo .mphb-attribute-value, .page-template-template-villages .mphb-attribute-value, .page-template-default  .mphb-attribute-value").each(function () {
  $(this).html($(this).html().replace(/,/g, ''));
});
 

$('.villa-lana select#mphb_room_details-0-adults option[value="1"], .vila-lana select#mphb_room_details-0-adults option[value="1"], .villa-kalina select#mphb_room_details-0-adults option[value="1"], .vila-kalina select#mphb_room_details-0-adults option[value="1"]').attr('disabled','disabled');
  
  
$('.maka’s-house select.mphb_sc_checkout-guests-chooser, .makina-kuća select.mphb_sc_checkout-guests-chooser, .makina´s-–-haus select.mphb_sc_checkout-guests-chooser, .ethnohaus-dujdjerovi select.mphb_sc_checkout-guests-chooser, .kuća-dundjerovih select.mphb_sc_checkout-guests-chooser, .dundjerovi’s-house select.mphb_sc_checkout-guests-chooser').change(function() {
  
  if($(this).attr('name').includes('adults')){
    const selectedAdults = $(this).val();
    const selectedChildren = $(this).closest('p').next().find('select');
    if(selectedAdults == '2') {
      selectedChildren.val("0").change();
      selectedChildren.prop('disabled', 'disabled'); 
    } else {
      selectedChildren.prop('disabled', false);
    }
  }

});

$('body.en select.mphb-rooms-quantity').change(function () { 
  // console.log($(this).val());
  const selectVal = $(this).val();

  $('.popup-reservation p').text('');
  $('.popup-reservation').addClass('active');
 
  $('.popup-reservation p').text('You have booked ' + selectVal + ' double rooms, which can accommodate up to ' + (selectVal * 2) + ' people');
  
});

$('body.de select.mphb-rooms-quantity').change(function () { 
  // console.log($(this).val());
  const selectVal = $(this).val();

  $('.popup-reservation p').text('');
  $('.popup-reservation').addClass('active');
  $('.popup-reservation p').text('Sie haben ' + selectVal + ' Doppelzimmer gebucht, die Platz für bis zu ' + (selectVal * 2) + ' Personen bieten');

});

$('body.sr select.mphb-rooms-quantity').change(function () { 
  // console.log($(this).val());
  const selectVal = $(this).val();

  $('.popup-reservation p').text('');
  $('.popup-reservation').addClass('active');
  $('.popup-reservation p').text('Rezervisali ste ' + selectVal + ' dvokrevetne sobe, koje mogu da prime do ' + (selectVal * 2) + ' osobe');

});
 

// $('.video.image').on('click', function() {
//   console.log('clicl');
//     $('#villagesModal').modal('show');
// })

/*
 * Video modal 
 */
$("body").on("click", ".video.image", function (e) {
  e.preventDefault();
  $("#video-modal").modal("show");
  $("#video-modal iframe")[0].src += "?autoplay=1";
});

$("#video-modal").on('hidden.bs.modal', function (e) {
  var videoUrl = $("#video-modal iframe")[0].src;
  videoUrl = videoUrl.replace("?autoplay=1", "");
  $("#video-modal iframe")[0].src = videoUrl;
});

/*
 * Change text button based on language
 */

if ($('body').hasClass('en')) {
  $(".mphb-book-button").text('Check Availability');
} else if ($('body').hasClass('sr')) {
  $(".mphb-book-button").text('Proveri Dostupnost');
}